.xpack-o-root {
  @include border-right;
  height: min-content;
  display: flex;
  flex-direction: row;
  gap: px2rem(1px);
  overflow: hidden auto;
  background-color: $body-bg;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;
  line-height: 1.5;

  @include lg {
    width: auto;
    flex-direction: column;
    height: 100vh;
    --navbar-max-width: 100%;
    position: relative;
    width: 100%;
    box-shadow: unset;
  }

  &__title {
    display: flex;
    align-items: center;

    @include lg {
      display: none;
    }
  }

  &__button-close,
  &__button-setting {
    width: px2rem(30px);
    height: px2rem(30px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: $body-color;

    svg.xpack-o-root__control-svg {
      height: px2rem(28px);
      width: px2rem(28px);
    }

    &:hover {
      color: $link-hover-color;
      transform: scale(1.05);
    }

    @include lg {
      display: none;
    }
  }

  &__button-setting {
    padding: px2rem(2px);
  }

  &__nav-items {
    overflow: hidden;
    flex-grow: 1;
    position: fixed;
    top: px2rem(35px);
    left: 0;
    max-width: var(--navbar-max-width);
    transform: translateX(-100%);
    transition: all 0.3s ease;
    background-color: $body-bg;
    border-bottom: px2rem(1px) solid $border-color;

    &.show {
      display: block;
      box-shadow: 0 px2rem(2px) px2rem(4px) #a6a6a6;
      transform: translateX(0);
      transition: transform 0.2s ease-out;
    }

    @include lg {
      position: relative;
      top: 0;
      display: block;
      padding-bottom: px2rem(8px);
      transform: translateX(0);
    }
  }

  &__items {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - px2rem(42px));
  }

  &__search {
    padding: px2rem(8px) px2rem(16px);
    border-bottom: px2rem(1px) solid $border-color;

    input {
      @include input;
      width: 100%;
      padding: px2rem(8px);
    }
  }

  &__nav-message {
    padding: px2rem(8px) px2rem(16px);
    font-weight: 700;
  }

  &__search-matches {
    border-bottom: px2rem(1px) solid $border-color;

    > * {
      font-weight: 700;
    }
  }

  &__search-not-matches {
    &.blur {
      opacity: 0.5;
    }
  }

  &__nav-item {
    padding: px2rem(4px) px2rem(8px) px2rem(4px) px2rem(16px);
    display: block;
    color: $body-color;
    font-size: px2rem(13px);
    font-family: Arial, Helvetica, sans-serif;

    &--active {
      color: $link-hover-color;
      background-color: rgba($body-color-rgb, 0.2);
    }

    &:hover {
      color: $link-hover-color;
    }

    &.panel-position {
      display: none;

      @include lg {
        display: flex;
      }
    }
  }

  &__frame-control {
    display: block;
    max-width: var(--navbar-max-width);
    margin-left: auto;

    @include lg {
      margin-left: unset;
    }
  }

  &__active-item-options {
    display: block;
    max-width: var(--navbar-max-width);
    background-color: $body-bg;
    position: fixed;
    top: px2rem(31px);
    right: 0;
    padding: px2rem(20px) 0;
    transform: translateX(100%);
    transition: all 0.3s ease;

    &.show {
      display: block;
      box-shadow: 0 px2rem(2px) px2rem(4px) #a6a6a6;
      transform: translateX(0);
      transition: transform 0.2s ease-out;
    }

    @include lg {
      display: block;
      position: relative;
      transform: translateX(0);
      top: 0;
    }
  }

  &__actual-width {
    margin-top: px2rem(4px);
    margin-right: px2rem(10px);
    text-align: center;
    font-size: px2rem(13px);

    @include lg {
      margin-top: px2rem(8px);
    }
  }

  &__controls {
    margin-top: px2rem(10px);
    text-align: center;
    display: none;

    @include lg {
      display: block;
    }
  }

  &__control-button {
    border: none;
    background-color: transparent;
    color: $body-color;
    cursor: pointer;

    &:hover {
      color: $link-hover-color;
    }
  }

  &__control-svg {
    width: px2rem(18px);
    height: px2rem(18px);
  }
}
